.app {
  text-align: center;
  margin: 0 0 5em;
}

.about {
  display: inline-block;
  margin: 0 auto;
}

.bio {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  /* width: 75%; */
  margin-bottom: 3%;
}

.bio p {
  margin-left: 5%;
  margin-top: 10%;
}

.about, .projects {
  margin-top: 1.25em;
}

.skills-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
}

.skill {
  margin-top: .5em;
  margin-bottom: .5em;
}

.icons {
  height: 2em;
  padding: 2em;
  display: inline-block;
}

.icon:hover {
  color: orange;
}

.font-awesome {
  color: blue;
}

.devicon-html5-plain-wordmark, .devicon-css3-plain-wordmark, .devicon-javascript-plain, .devicon-nodejs-plain, .devicon-react-original-wordmark, .devicon-python-plain-wordmark, .devicon-postgresql-plain-wordmark, .devicon-mongodb-plain-wordmark, .devicon-express-original, .devicon-sequelize-plain, .devicon-sass-original, .devicon-bootstrap-plain-wordmark {
  font-size: 3em;
}

.card {
  margin-top: .5em;
  min-height: 20em;
}

.card-text {
  padding-top: .5em;
}

.card-image {
  opacity: 0.6;
}

.live-link {
  background-color: blue;
}

.contact {
  margin-top: 6.5em;
}

p {
  color: gray;
}

img {
  padding-top: 15px;
}

footer {
  height: 7vh;
  width: 100vw;
  bottom: 0;
  background-color: #41545E;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}








